import { default as consort_45differencewgCPyjhbbJMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/about/consort-difference.vue?macro=true";
import { default as indexigvk75pzgyMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/about/consort-homes-reviews-and-testimonials/category/[category]/index.vue?macro=true";
import { default as indexBQDL0tHNX4Meta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/about/consort-homes-reviews-and-testimonials/index.vue?macro=true";
import { default as indexM40hrn3XqvMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/about/events/[event]/index.vue?macro=true";
import { default as indexsY6Zaq9d2jMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/about/events/index.vue?macro=true";
import { default as our_45processr8BDgm1WKAMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/about/our-process.vue?macro=true";
import { default as our_45storyHuO3Am5lGbMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/about/our-story.vue?macro=true";
import { default as our_45teamv9ErBgwQsMMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/about/our-team.vue?macro=true";
import { default as why_45consort44wW5fu4dpMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/about/why-consort.vue?macro=true";
import { default as callbackTrzvvQzMTMMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/callback.vue?macro=true";
import { default as _91_46_46_46slug_93DOxLrzIjkQMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as indexMwBJzg4lsUMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as career_45opportunitiesdm8V20Qf2lMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as contact_45uskDi49LjFYYMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/contact-us/contact-us.vue?macro=true";
import { default as for_45realtorsLSdLZwOQ97Meta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/contact-us/for-realtors.vue?macro=true";
import { default as our_45locationsllTaGkSbWeMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as request_45an_45appointment7EgkUGPII3Meta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/contact-us/request-an-appointment.vue?macro=true";
import { default as join_45vip_45listhSG1PtNauUMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/current-offers/join-vip-list.vue?macro=true";
import { default as why_45buy_45nowSoWD9lBBetMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/current-offers/why-buy-now.vue?macro=true";
import { default as indexbW53HyhcstMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/display-homes/[model]/index.vue?macro=true";
import { default as indexLfpBLaUAIEMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/gallery/heritage-gallery/index.vue?macro=true";
import { default as index0EtHwUGmCnMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/gallery/hometown-gallery/index.vue?macro=true";
import { default as index2eUYAyryLpMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/gallery/photos/index.vue?macro=true";
import { default as index1UYwPPguI8Meta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/gallery/product-gallery/index.vue?macro=true";
import { default as indexMEXitpWK0aMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/gallery/video/index.vue?macro=true";
import { default as indexRfCISjfNZzMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/gallery/virtual-tours/index.vue?macro=true";
import { default as indexX2T9goyiYaMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/index.vue?macro=true";
import { default as interactive_45home_45designzqGIZZIaY8Meta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/interactive-home-design.vue?macro=true";
import { default as my_45favoritesmrpLgvCpqaMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as index4dgu2J2hLTMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/new-homes/homes/[home]/index.vue?macro=true";
import { default as indexCN2LAghTFKMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/new-homes/plans/[plan]/index.vue?macro=true";
import { default as indexuoazYfQK16Meta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/new-neighborhoods/[location]/[community]/index.vue?macro=true";
import { default as build_45on_45your_45lot9jxhWEi75lMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/our-homes/build-on-your-lot.vue?macro=true";
import { default as design_45processfUv5tfz9kAMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/our-homes/design-process.vue?macro=true";
import { default as financingiluIjd6dGFMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/our-homes/financing.vue?macro=true";
import { default as indexW1pvQO1PJZMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as privacy_45policyvMUXN5YbGUMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/privacy-policy.vue?macro=true";
import { default as _91_46_46_46slug_93tpJMgEd5bqMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/promotions/[...slug].vue?macro=true";
import { default as indexqzcckHyYQyMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/promotions/index.vue?macro=true";
import { default as sandboxQIHmC90WzkMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/sandbox.vue?macro=true";
import { default as self_45guided_45home_45toursJDZwGULty2Meta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/self-guided-home-tours.vue?macro=true";
import { default as _91testimonial_93b9xCNJqmHEMeta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/testimonials/[testimonial].vue?macro=true";
import { default as where_45we_45buildepaBkfvoT8Meta } from "/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/where-we-build.vue?macro=true";
export default [
  {
    name: consort_45differencewgCPyjhbbJMeta?.name ?? "about-consort-difference",
    path: consort_45differencewgCPyjhbbJMeta?.path ?? "/about/consort-difference",
    meta: consort_45differencewgCPyjhbbJMeta || {},
    alias: consort_45differencewgCPyjhbbJMeta?.alias || [],
    redirect: consort_45differencewgCPyjhbbJMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/about/consort-difference.vue").then(m => m.default || m)
  },
  {
    name: indexigvk75pzgyMeta?.name ?? "about-consort-homes-reviews-and-testimonials-category-category",
    path: indexigvk75pzgyMeta?.path ?? "/about/consort-homes-reviews-and-testimonials/category/:category()",
    meta: indexigvk75pzgyMeta || {},
    alias: indexigvk75pzgyMeta?.alias || [],
    redirect: indexigvk75pzgyMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/about/consort-homes-reviews-and-testimonials/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexBQDL0tHNX4Meta?.name ?? "about-consort-homes-reviews-and-testimonials",
    path: indexBQDL0tHNX4Meta?.path ?? "/about/consort-homes-reviews-and-testimonials",
    meta: indexBQDL0tHNX4Meta || {},
    alias: indexBQDL0tHNX4Meta?.alias || [],
    redirect: indexBQDL0tHNX4Meta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/about/consort-homes-reviews-and-testimonials/index.vue").then(m => m.default || m)
  },
  {
    name: indexM40hrn3XqvMeta?.name ?? "about-events-event",
    path: indexM40hrn3XqvMeta?.path ?? "/about/events/:event()",
    meta: indexM40hrn3XqvMeta || {},
    alias: indexM40hrn3XqvMeta?.alias || [],
    redirect: indexM40hrn3XqvMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/about/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexsY6Zaq9d2jMeta?.name ?? "about-events",
    path: indexsY6Zaq9d2jMeta?.path ?? "/about/events",
    meta: indexsY6Zaq9d2jMeta || {},
    alias: indexsY6Zaq9d2jMeta?.alias || [],
    redirect: indexsY6Zaq9d2jMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/about/events/index.vue").then(m => m.default || m)
  },
  {
    name: our_45processr8BDgm1WKAMeta?.name ?? "about-our-process",
    path: our_45processr8BDgm1WKAMeta?.path ?? "/about/our-process",
    meta: our_45processr8BDgm1WKAMeta || {},
    alias: our_45processr8BDgm1WKAMeta?.alias || [],
    redirect: our_45processr8BDgm1WKAMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/about/our-process.vue").then(m => m.default || m)
  },
  {
    name: our_45storyHuO3Am5lGbMeta?.name ?? "about-our-story",
    path: our_45storyHuO3Am5lGbMeta?.path ?? "/about/our-story",
    meta: our_45storyHuO3Am5lGbMeta || {},
    alias: our_45storyHuO3Am5lGbMeta?.alias || [],
    redirect: our_45storyHuO3Am5lGbMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/about/our-story.vue").then(m => m.default || m)
  },
  {
    name: our_45teamv9ErBgwQsMMeta?.name ?? "about-our-team",
    path: our_45teamv9ErBgwQsMMeta?.path ?? "/about/our-team",
    meta: our_45teamv9ErBgwQsMMeta || {},
    alias: our_45teamv9ErBgwQsMMeta?.alias || [],
    redirect: our_45teamv9ErBgwQsMMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/about/our-team.vue").then(m => m.default || m)
  },
  {
    name: why_45consort44wW5fu4dpMeta?.name ?? "about-why-consort",
    path: why_45consort44wW5fu4dpMeta?.path ?? "/about/why-consort",
    meta: why_45consort44wW5fu4dpMeta || {},
    alias: why_45consort44wW5fu4dpMeta?.alias || [],
    redirect: why_45consort44wW5fu4dpMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/about/why-consort.vue").then(m => m.default || m)
  },
  {
    name: callbackTrzvvQzMTMMeta?.name ?? "callback",
    path: callbackTrzvvQzMTMMeta?.path ?? "/callback",
    meta: callbackTrzvvQzMTMMeta || {},
    alias: callbackTrzvvQzMTMMeta?.alias || [],
    redirect: callbackTrzvvQzMTMMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/callback.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93DOxLrzIjkQMeta?.name ?? "campaigns-slug",
    path: _91_46_46_46slug_93DOxLrzIjkQMeta?.path ?? "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93DOxLrzIjkQMeta || {},
    alias: _91_46_46_46slug_93DOxLrzIjkQMeta?.alias || [],
    redirect: _91_46_46_46slug_93DOxLrzIjkQMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexMwBJzg4lsUMeta?.name ?? "campaigns",
    path: indexMwBJzg4lsUMeta?.path ?? "/campaigns",
    meta: indexMwBJzg4lsUMeta || {},
    alias: indexMwBJzg4lsUMeta?.alias || [],
    redirect: indexMwBJzg4lsUMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: career_45opportunitiesdm8V20Qf2lMeta?.name ?? "contact-us-career-opportunities",
    path: career_45opportunitiesdm8V20Qf2lMeta?.path ?? "/contact-us/career-opportunities",
    meta: career_45opportunitiesdm8V20Qf2lMeta || {},
    alias: career_45opportunitiesdm8V20Qf2lMeta?.alias || [],
    redirect: career_45opportunitiesdm8V20Qf2lMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: contact_45uskDi49LjFYYMeta?.name ?? "contact-us-contact-us",
    path: contact_45uskDi49LjFYYMeta?.path ?? "/contact-us/contact-us",
    meta: contact_45uskDi49LjFYYMeta || {},
    alias: contact_45uskDi49LjFYYMeta?.alias || [],
    redirect: contact_45uskDi49LjFYYMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/contact-us/contact-us.vue").then(m => m.default || m)
  },
  {
    name: for_45realtorsLSdLZwOQ97Meta?.name ?? "contact-us-for-realtors",
    path: for_45realtorsLSdLZwOQ97Meta?.path ?? "/contact-us/for-realtors",
    meta: for_45realtorsLSdLZwOQ97Meta || {},
    alias: for_45realtorsLSdLZwOQ97Meta?.alias || [],
    redirect: for_45realtorsLSdLZwOQ97Meta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/contact-us/for-realtors.vue").then(m => m.default || m)
  },
  {
    name: our_45locationsllTaGkSbWeMeta?.name ?? "contact-us-our-locations",
    path: our_45locationsllTaGkSbWeMeta?.path ?? "/contact-us/our-locations",
    meta: our_45locationsllTaGkSbWeMeta || {},
    alias: our_45locationsllTaGkSbWeMeta?.alias || [],
    redirect: our_45locationsllTaGkSbWeMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: request_45an_45appointment7EgkUGPII3Meta?.name ?? "contact-us-request-an-appointment",
    path: request_45an_45appointment7EgkUGPII3Meta?.path ?? "/contact-us/request-an-appointment",
    meta: request_45an_45appointment7EgkUGPII3Meta || {},
    alias: request_45an_45appointment7EgkUGPII3Meta?.alias || [],
    redirect: request_45an_45appointment7EgkUGPII3Meta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/contact-us/request-an-appointment.vue").then(m => m.default || m)
  },
  {
    name: join_45vip_45listhSG1PtNauUMeta?.name ?? "current-offers-join-vip-list",
    path: join_45vip_45listhSG1PtNauUMeta?.path ?? "/current-offers/join-vip-list",
    meta: join_45vip_45listhSG1PtNauUMeta || {},
    alias: join_45vip_45listhSG1PtNauUMeta?.alias || [],
    redirect: join_45vip_45listhSG1PtNauUMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/current-offers/join-vip-list.vue").then(m => m.default || m)
  },
  {
    name: why_45buy_45nowSoWD9lBBetMeta?.name ?? "current-offers-why-buy-now",
    path: why_45buy_45nowSoWD9lBBetMeta?.path ?? "/current-offers/why-buy-now",
    meta: why_45buy_45nowSoWD9lBBetMeta || {},
    alias: why_45buy_45nowSoWD9lBBetMeta?.alias || [],
    redirect: why_45buy_45nowSoWD9lBBetMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/current-offers/why-buy-now.vue").then(m => m.default || m)
  },
  {
    name: indexbW53HyhcstMeta?.name ?? "display-homes-model",
    path: indexbW53HyhcstMeta?.path ?? "/display-homes/:model()",
    meta: indexbW53HyhcstMeta || {},
    alias: indexbW53HyhcstMeta?.alias || [],
    redirect: indexbW53HyhcstMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/display-homes/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfpBLaUAIEMeta?.name ?? "gallery-heritage-gallery",
    path: indexLfpBLaUAIEMeta?.path ?? "/gallery/heritage-gallery",
    meta: indexLfpBLaUAIEMeta || {},
    alias: indexLfpBLaUAIEMeta?.alias || [],
    redirect: indexLfpBLaUAIEMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/gallery/heritage-gallery/index.vue").then(m => m.default || m)
  },
  {
    name: index0EtHwUGmCnMeta?.name ?? "gallery-hometown-gallery",
    path: index0EtHwUGmCnMeta?.path ?? "/gallery/hometown-gallery",
    meta: index0EtHwUGmCnMeta || {},
    alias: index0EtHwUGmCnMeta?.alias || [],
    redirect: index0EtHwUGmCnMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/gallery/hometown-gallery/index.vue").then(m => m.default || m)
  },
  {
    name: index2eUYAyryLpMeta?.name ?? "gallery-photos",
    path: index2eUYAyryLpMeta?.path ?? "/gallery/photos",
    meta: index2eUYAyryLpMeta || {},
    alias: index2eUYAyryLpMeta?.alias || [],
    redirect: index2eUYAyryLpMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/gallery/photos/index.vue").then(m => m.default || m)
  },
  {
    name: index1UYwPPguI8Meta?.name ?? "gallery-product-gallery",
    path: index1UYwPPguI8Meta?.path ?? "/gallery/product-gallery",
    meta: index1UYwPPguI8Meta || {},
    alias: index1UYwPPguI8Meta?.alias || [],
    redirect: index1UYwPPguI8Meta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/gallery/product-gallery/index.vue").then(m => m.default || m)
  },
  {
    name: indexMEXitpWK0aMeta?.name ?? "gallery-video",
    path: indexMEXitpWK0aMeta?.path ?? "/gallery/video",
    meta: indexMEXitpWK0aMeta || {},
    alias: indexMEXitpWK0aMeta?.alias || [],
    redirect: indexMEXitpWK0aMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/gallery/video/index.vue").then(m => m.default || m)
  },
  {
    name: indexRfCISjfNZzMeta?.name ?? "gallery-virtual-tours",
    path: indexRfCISjfNZzMeta?.path ?? "/gallery/virtual-tours",
    meta: indexRfCISjfNZzMeta || {},
    alias: indexRfCISjfNZzMeta?.alias || [],
    redirect: indexRfCISjfNZzMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/gallery/virtual-tours/index.vue").then(m => m.default || m)
  },
  {
    name: indexX2T9goyiYaMeta?.name ?? "index",
    path: indexX2T9goyiYaMeta?.path ?? "/",
    meta: indexX2T9goyiYaMeta || {},
    alias: indexX2T9goyiYaMeta?.alias || [],
    redirect: indexX2T9goyiYaMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: interactive_45home_45designzqGIZZIaY8Meta?.name ?? "interactive-home-design",
    path: interactive_45home_45designzqGIZZIaY8Meta?.path ?? "/interactive-home-design",
    meta: interactive_45home_45designzqGIZZIaY8Meta || {},
    alias: interactive_45home_45designzqGIZZIaY8Meta?.alias || [],
    redirect: interactive_45home_45designzqGIZZIaY8Meta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/interactive-home-design.vue").then(m => m.default || m)
  },
  {
    name: my_45favoritesmrpLgvCpqaMeta?.name ?? "my-favorites",
    path: my_45favoritesmrpLgvCpqaMeta?.path ?? "/my-favorites",
    meta: my_45favoritesmrpLgvCpqaMeta || {},
    alias: my_45favoritesmrpLgvCpqaMeta?.alias || [],
    redirect: my_45favoritesmrpLgvCpqaMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: index4dgu2J2hLTMeta?.name ?? "new-homes-homes-home",
    path: index4dgu2J2hLTMeta?.path ?? "/new-homes/homes/:home()",
    meta: index4dgu2J2hLTMeta || {},
    alias: index4dgu2J2hLTMeta?.alias || [],
    redirect: index4dgu2J2hLTMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/new-homes/homes/[home]/index.vue").then(m => m.default || m)
  },
  {
    name: indexCN2LAghTFKMeta?.name ?? "new-homes-plans-plan",
    path: indexCN2LAghTFKMeta?.path ?? "/new-homes/plans/:plan()",
    meta: indexCN2LAghTFKMeta || {},
    alias: indexCN2LAghTFKMeta?.alias || [],
    redirect: indexCN2LAghTFKMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/new-homes/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: indexuoazYfQK16Meta?.name ?? "new-neighborhoods-location-community",
    path: indexuoazYfQK16Meta?.path ?? "/new-neighborhoods/:location()/:community()",
    meta: indexuoazYfQK16Meta || {},
    alias: indexuoazYfQK16Meta?.alias || [],
    redirect: indexuoazYfQK16Meta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/new-neighborhoods/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: build_45on_45your_45lot9jxhWEi75lMeta?.name ?? "our-homes-build-on-your-lot",
    path: build_45on_45your_45lot9jxhWEi75lMeta?.path ?? "/our-homes/build-on-your-lot",
    meta: build_45on_45your_45lot9jxhWEi75lMeta || {},
    alias: build_45on_45your_45lot9jxhWEi75lMeta?.alias || [],
    redirect: build_45on_45your_45lot9jxhWEi75lMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/our-homes/build-on-your-lot.vue").then(m => m.default || m)
  },
  {
    name: design_45processfUv5tfz9kAMeta?.name ?? "our-homes-design-process",
    path: design_45processfUv5tfz9kAMeta?.path ?? "/our-homes/design-process",
    meta: design_45processfUv5tfz9kAMeta || {},
    alias: design_45processfUv5tfz9kAMeta?.alias || [],
    redirect: design_45processfUv5tfz9kAMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/our-homes/design-process.vue").then(m => m.default || m)
  },
  {
    name: financingiluIjd6dGFMeta?.name ?? "our-homes-financing",
    path: financingiluIjd6dGFMeta?.path ?? "/our-homes/financing",
    meta: financingiluIjd6dGFMeta || {},
    alias: financingiluIjd6dGFMeta?.alias || [],
    redirect: financingiluIjd6dGFMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/our-homes/financing.vue").then(m => m.default || m)
  },
  {
    name: indexW1pvQO1PJZMeta?.name ?? "our-homes",
    path: indexW1pvQO1PJZMeta?.path ?? "/our-homes",
    meta: indexW1pvQO1PJZMeta || {},
    alias: indexW1pvQO1PJZMeta?.alias || [],
    redirect: indexW1pvQO1PJZMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyvMUXN5YbGUMeta?.name ?? "privacy-policy",
    path: privacy_45policyvMUXN5YbGUMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyvMUXN5YbGUMeta || {},
    alias: privacy_45policyvMUXN5YbGUMeta?.alias || [],
    redirect: privacy_45policyvMUXN5YbGUMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93tpJMgEd5bqMeta?.name ?? "promotions-slug",
    path: _91_46_46_46slug_93tpJMgEd5bqMeta?.path ?? "/promotions/:slug(.*)*",
    meta: _91_46_46_46slug_93tpJMgEd5bqMeta || {},
    alias: _91_46_46_46slug_93tpJMgEd5bqMeta?.alias || [],
    redirect: _91_46_46_46slug_93tpJMgEd5bqMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/promotions/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexqzcckHyYQyMeta?.name ?? "promotions",
    path: indexqzcckHyYQyMeta?.path ?? "/promotions",
    meta: indexqzcckHyYQyMeta || {},
    alias: indexqzcckHyYQyMeta?.alias || [],
    redirect: indexqzcckHyYQyMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/promotions/index.vue").then(m => m.default || m)
  },
  {
    name: sandboxQIHmC90WzkMeta?.name ?? "sandbox",
    path: sandboxQIHmC90WzkMeta?.path ?? "/sandbox",
    meta: sandboxQIHmC90WzkMeta || {},
    alias: sandboxQIHmC90WzkMeta?.alias || [],
    redirect: sandboxQIHmC90WzkMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: self_45guided_45home_45toursJDZwGULty2Meta?.name ?? "self-guided-home-tours",
    path: self_45guided_45home_45toursJDZwGULty2Meta?.path ?? "/self-guided-home-tours",
    meta: self_45guided_45home_45toursJDZwGULty2Meta || {},
    alias: self_45guided_45home_45toursJDZwGULty2Meta?.alias || [],
    redirect: self_45guided_45home_45toursJDZwGULty2Meta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/self-guided-home-tours.vue").then(m => m.default || m)
  },
  {
    name: _91testimonial_93b9xCNJqmHEMeta?.name ?? "testimonials-testimonial",
    path: _91testimonial_93b9xCNJqmHEMeta?.path ?? "/testimonials/:testimonial()",
    meta: _91testimonial_93b9xCNJqmHEMeta || {},
    alias: _91testimonial_93b9xCNJqmHEMeta?.alias || [],
    redirect: _91testimonial_93b9xCNJqmHEMeta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/testimonials/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: where_45we_45buildepaBkfvoT8Meta?.name ?? "where-we-build",
    path: where_45we_45buildepaBkfvoT8Meta?.path ?? "/where-we-build",
    meta: where_45we_45buildepaBkfvoT8Meta || {},
    alias: where_45we_45buildepaBkfvoT8Meta?.alias || [],
    redirect: where_45we_45buildepaBkfvoT8Meta?.redirect,
    component: () => import("/codebuild/output/src3557300418/src/consort-headless/nuxtapp/pages/where-we-build.vue").then(m => m.default || m)
  }
]